@import './variables.scss';
@import '~antd/dist/antd.css';

.app {
  height: 100%;
  width: 100%;
  color: var(--white);
  background: var(--primary);
}
.fake-div {
  height: 70px;
}
.zw-container{
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  width: 100%;
  max-width: 1440px; 
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}
body {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Poppins', sans-serif;
}
@media (max-width:767px){
  .zw-container{
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    width: 100%;
    // max-width: 1440px; 
    // z-index: 1;
  }
  h1{
    font-size: 24px;
  }
}