@import '../styles/mixins.scss';

.content {
  height: 100%;
  @include flex(row, center, center, 60px);
}
.col-1 {
  width: 100%;
  z-index: 1;
  @include flex(column, flex-start, center, 20px);
  &.align-center{
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.col-2 {
  z-index: 1;
  width: 100%;
  // max-width: 450px;
  .content {
    @include flex(column, flex-start, center, 20px);
    background: var(--white);
    border-radius: 20px;
    padding: 40px;
    .success-message {
      width: 100%;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      background: var(--light-green);
      color: var(--dark-green);
    }
    .form-label>div {
      text-align: center;
    }
    .input-area {
      width: 100%;
      @include flex(row, center, center, '');
      flex: 1 1 100%;
      .number {
        flex: 1 1 20%;
        border-radius: 25px 0 0 25px;
        color: var(--grey-one);
        background: var(--grey-two);
        text-align: center;
        padding: 4px;
        border: 1px solid var(--grey-one);
      }
      .input {
        flex: 1 1 80%;
        border-radius: 0 25px 25px 0;
      }
    }
    .checkbox {
      color: var(--grey);
      &.checked > span > span {
        background: var(--primary);
      }
    }
    .checkbox > span::after {
      border: 1px solid var(--primary);
    }
    .checkbox > span > span {
      border-color: var(--primary);
    }
    .button {
      width: 100%;
      background: var(--primary);
      border-radius: 25px;
      border: 1px solid var(--primary);
      &.disabled {
        color: var(--grey-one);
        background: var(--grey-two);
        border: 1px solid var(--grey-one);
      }
    }
    .forgot-pin {
      width: 100%;
      @include flex(row, center, space-between, "");
      flex: 1 1 100%;
      .till-24-hours, .after-24-hours {
        flex: 1 1 50%;
      }
      .till-24-hours {
        @include flex(column, center, center, 5px);
        border-right: 1px solid var(--grey-one);
      }
      .after-24-hours {
        @include flex(column, center, center, 5px);
      }
      .span {
        @include flex(row, center, "", 10px);
      }
    }
  }
  .info-label {
    margin: 20px;
    text-align: center;
  }
}


@media (max-width:767px){
  .col-1{
    // display: block;
    align-items: center;
    gap: 10px;
    text-align: center;
    margin-bottom: 40px;

    svg{
      height: 40px;
      width: 40px;
      float: left;
      margin: 2px;
      margin-right: 10px;
    }
    h1{
      margin: 0px;
      font-size: 24px;
    }
    span:last-child{
      width: 100%;
    }
  }
  .col-2{
    max-width: 100%;
    margin-bottom: 40px;
  }
}
// @media (max-width:)