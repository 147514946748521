@import '../../styles/mixins.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 0px;
  height: 70px;
  width: 100%;
  z-index: 2;
  background: var(--primary); 
  // border: 1px solid black;
  .header-container{
    @include flex(row, center, space-between, '');
  }
  .header-logo{
    line-height: 1;
  }
  .links {
    ul{
      @include flex(row, center, '', 10px);
      margin: 0;
      list-style-type: none;
      li{
        padding: 0 5px;
        &.active a{
          border-bottom: 2px solid var(--white);
          font-weight: 600;
        }
      }
    }
    a {
      color: inherit;
      font-size: 12px;
      text-decoration: none;
      cursor: pointer;
      padding: 5px;
    }
  }
}
.hamburger-icon{
  display: none;
}

@media (max-width:767px){
  .header{
    // padding-left: 20px;
    // padding-right: 20px;
    .hamburger-icon{
      display: inline-block;
      z-index: 2;
      color: #fff;
      svg{
        width: 24px;
        height: 24px;
        #top-line{
          transform: translateX(3px);
          transition: .3s;
        }
        #bottom-line{
          transition: .3s;
          transform: translateX(6px);
        }
      }
      &.is-opened{
        
        svg{
          #center-line{
            display: none;
          }
          #top-line{
            transform: rotate( -45deg);
            transform-origin: 20px 9px;
            d: path("M 6 7 H 18");
          }
          #bottom-line{
            transform: rotate(45deg);
            transform-origin: 17px 16px;
            d: path("M 6 17 H 18");
          }
        }
        
      }
    }
    nav.links{
      position: absolute;
      height: 100vh;
      display: flex;
      /* flex-direction: column; */
      width: calc(100vw - 90px);
      background: #4f2e94;
      top: 0;
      right: 0;
      align-items: center;
      padding: 20px;
      box-shadow: 0px -1px 8px 0px #4f2e94;
      background: linear-gradient(149deg, #4f2e94 60%, #7b57c5);
      transform: translateX(103vw);
      transition: .4s;
      ul{
        flex-direction: column;
        margin: 0 auto;
      }
      a{
        font-size: 16px;
        font-weight: 300;
      }
      &.is-opened{
        transform: translateX(20px);
        transition: .6s;
      }
    }
  }
}