@font-face {
  font-family: 'Avenir';
  src: url('font/Metropolis-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  overflow: hidden;
  height: 100vh;
}
#root {
  height: 100%;
}
body,
#root {
  font-size: 12px;
}
