.wave{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    svg{
        width: 100%;
        height: auto;
        path{
            width: 100%;
        }
    }
}