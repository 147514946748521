.container {
  width: 100%;
  padding: 10px;
  text-align: center;
  .loader {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid var(--primary);
    border-radius: 50%;
    border-top-color: var(--white);
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
