:root {
    --primary: #502e94;
    --white: #ffffff;
    --grey: #393247;
    --orange: #f27949;
    --purple-one: #9c7ae1;
    --purple-two: #6844af;
    --grey-one: #9c93ae;
    --grey-two: #f6f4fb;
    --light-green: #ccf2df;
    --dark-green: #31882b;
    --pink: #ffdddd;
    --dark-red: #e15454;
    --red: #e66c6c;
    --green: #66b23c;
    --light-green: #CCF2D5;
}
