.main {
  height: calc(100% - 70px);
  // z-index: 1;
  // padding: 20px;
  &.centered{
    display: flex;
    align-items: center;
  }
}

